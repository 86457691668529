<main class="main-div"
    [ngStyle]="{'overflow': authService.validationResult === ValidationStatus.Valid ? 'auto' : 'hidden'}">

    <div *ngIf="authService.validationResult !== ValidationStatus.Valid && showStartPage; else mainSectionTemp"
        class="start-content">
        <div class="flex-center splash-container">
            <div class="login-content">
                <div class="logo-bar"></div>
                <button mat-raised-button type="button" class="prm-btn fs-btn-txt loader-btn" id="sendlogin"
                    [disabled]="loader" (click)="checkAuthentication()">
                    <span>Start</span>
                </button>
            </div>
        </div>
    </div>
    <ng-template #mainSectionTemp>
        <div *ngIf="authService.validationResult === ValidationStatus.Valid">
            <nav-bar></nav-bar>
        </div>
        <div class="router-container" *ngIf="authService.validationResult !== ValidationStatus.Auth"
            [ngClass]="authService.validationResult === ValidationStatus.Valid ? 'content-wrapper' : ''">
            <router-outlet></router-outlet>
        </div>
    </ng-template>
</main>

<mini-loader></mini-loader>