import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthService } from 'src/app/shared/services/AuthService';
import { Router } from '@angular/router';
import { UserRoles } from 'src/app/shared/models/enums';
import { environment } from 'src/environments/environment';
import {
  ElementValueModel,
  NavigationBarItem,
} from 'src/app/shared/models/Application.model';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { BreadCrumbService } from 'src/app/shared/modules/breadcrumb/breadcrumb.service';
import { NavItems } from './StaticData';
import { VersionModalComponent } from '../version-modal/version-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MODAL_DATA } from 'src/app/shared/staticObjects';
import { AppConfigService } from 'src/app/shared/services/app-config.service';
import { NavigationBarService } from './nav-bar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {
  @ViewChild('navRoutes') navRoutes: ElementRef<HTMLElement>;

  logo: ElementValueModel;
  breadCrumbIcons: ElementValueModel[];
  navbarIcons: ElementValueModel[];

  transformer = (node: NavigationBarItem, level: number) => {
    return {
      id: node.id,
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      route: node.route,
      urlParam: node.urlParam,
      activate: node.activate,
      children: node.children,
    };
  };
  treeControl = new FlatTreeControl<NavigationBarItem>(
    (node) => node.level,
    (node) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: NavigationBarItem) => node.expandable;

  email: string = '';
  userRole: UserRoles;
  currentRoute: string;
  navRouteToggle: boolean;
  navLoader: boolean;
  modalObjects = MODAL_DATA;

  navItems: NavigationBarItem[];
  navTitleArr: string[];

  openVerModalSub$: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private bcService: BreadCrumbService,
    private dialog: MatDialog,
    private appConfService: AppConfigService,
    public sanitizer: DomSanitizer,
    private navService: NavigationBarService
  ) {
    this.navLoader = true;
    this.navRouteToggle = false;
    this.navTitleArr = new Array<string>();
    this.logo = new ElementValueModel({});
    this.breadCrumbIcons = new Array<ElementValueModel>();
    this.navbarIcons = new Array<ElementValueModel>();
    this.navItems = NavItems;
    this.dataSource.data = NavItems;
  }

  ngOnDestroy(): void {
    this.openVerModalSub$?.unsubscribe();
  }

  setBreadcrumbValue(path: string, urlParam: string, name: string): void {
    setTimeout(() => {
      this.breadCrumbIcons =
        this.appConfService.get()?.elements?.breadcrumb?.icons;
      this.navbarIcons = this.appConfService.get()?.elements?.navbar?.icons;
      this.logo = this.appConfService.get()?.elements?.logo;

      if (this.breadCrumbIcons && this.breadCrumbIcons.length) {
        this.bcService.setBreadCrumb = {
          icon: this.breadCrumbIcons.find((f) => f.name === name)?.value,
          path: [
            {
              title: path,
              route: `${path}/${urlParam}`,
              depth: 1,
            },
          ],
        };
      }
    }, 200);
  }

  ngOnInit(): void {
    this.getUserRole();
    this.initialNavItemActive();
    this.authService.isTokenValid().then((res) => {
      if (!res) this.authService.signOut();
    });
    this.authService.getUserInfo().then((res) => {
      if (!res || !res.attributes) {
        this.authService.signOut();
      } else {
        this.displayEmail(res.attributes);
      }
    });
    //this.authService.isAuthenticated().then((res) => {
    //console.log('is authenticated ' + res);
    //});
  }

  initialNavItemActive(): void {
    let routeName =
        window.localStorage.getItem(environment.navName) ??
        environment.defaultPage,
      url = window.location.pathname;

    if (url && url.length) {
      url = url.substring(1, url.length);
    }

    if (url.indexOf(routeName) === -1) {
      this.changeRoute(environment.defaultPage, '', environment.defaultPage);
      return;
    }

    let routeObj = this.navItems.find((f) => f.name === routeName);

    //this.setNavTitle(routeName);
    this.setNavItemActivate(routeName);
    this.setBreadcrumbValue(routeObj.route, routeObj.urlParam, routeObj.name);

    if (routeObj.urlParam && routeObj.urlParam.length)
      routeName = `${routeObj.route}/${routeObj.name}`;
    else routeName = `/${routeObj.route}`;
  }

  async getUserRole(): Promise<any> {
    try {
      const result = await this.authService.getUserRoles();
      this.userRole = this.authService.selectUserRoles(result);
      this.navLoader = false;
    } catch (err) {
      console.error(err);
    }
  }

  setNavTitle(name: string): void {
    document.querySelectorAll('.side-nav ul li').forEach((el: HTMLElement) => {
      let r = el.dataset.navRoute;
      if (r !== name) {
        el.classList.add('side-nav-dis');
        el.classList.remove('side-nav-active');
      } else {
        el.classList.add('side-nav-active');
        el.classList.remove('side-nav-dis');
      }
    });
    document
      .querySelectorAll('.nav-routes ul li button')
      .forEach((el: HTMLElement) => {
        let r = el.dataset.navRoute;
        if (r !== name) {
          el.classList.remove('nav-route-active');
        } else {
          el.classList.add('nav-route-active');
        }
      });
  }

  checkBreadcrumbLink(title: string): boolean {
    let id = parseInt(title) || title;
    return typeof id === 'number' ? false : true;
  }

  paramToAddEdit(param: string): string {
    let p = parseInt(param);
    return p > 0 ? 'EDIT' : 'ADD';
  }

  public displayEmail<NodeCallback>(attrs: any) {
    this.email = attrs.email;
  }

  onLogoutClick() {
    // await this.authService.signOut();
    // this.router.navigate(['/login'], { replaceUrl: true }).then(() => {
    //   this.authService.validationResult = ValidationStatus.Invalid;
    // });
    this.authService.unautorized().then((_) => {
      window.location.reload();
    });
  }

  // onRefreshToken() {
  //   console.log('Logout Clicked');
  //   this.authService.refreshToken().then((res) => {
  //     if (res) this.authService.getTokenExpiration();
  //   });
  // }

  changeRoute(route: string, urlParam: string, name: string): void {
    let currentUrl = window.location.pathname;
    if (currentUrl === `/${route}/${urlParam}`) return;

    let fullUrl = route;

    if (urlParam && urlParam.length) {
      this.navService.setUrlParam(urlParam);
      fullUrl = `${route}/${urlParam}`;
    } else {
      this.navService.removeUrlParam();
      fullUrl = route;
    }

    //this.setNavTitle(name);
    this.setNavItemActivate(name);
    this.router.navigateByUrl(fullUrl);
    this.setBreadcrumbValue(route, urlParam, name);

    if (this.navRouteToggle) {
      this.toggleNavRoute();
    }
  }

  setNavItemActivate(name: string): void {
    if (name && name.length) {
      for (let index = 0; index < this.navItems.length; index++) {
        let item = this.navItems[index];
        item.name === name ? (item.activate = true) : (item.activate = false);
      }
      this.dataSource.data = this.navItems;
    }
  }

  toggleNavRoute(): void {
    this.navRouteToggle = !this.navRouteToggle;
    if (this.navRouteToggle) {
      this.navRoutes.nativeElement.style.left = '62px';
    } else {
      this.navRoutes.nativeElement.style.left = '-205px';
    }
  }

  onOpenVerModal(): void {
    const dialogRef = this.dialog.open(VersionModalComponent, {
      width: '600px',
      backdropClass: 'background-blur',
    });

    this.openVerModalSub$ = dialogRef.afterClosed().subscribe(() => {});
  }
}
