import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, ValidationStatus } from './shared/services/AuthService';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from './shared/services/app-config.service';
import { environment } from 'src/environments/environment';
import { MiniLoaderService } from './shared/modules/mini-loader/mini-loader.service';
import { HelperService } from './shared/services/Helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly ValidationStatus = ValidationStatus;

  loader: boolean;
  showStartPage: boolean;
  tokenHelper = new JwtHelperService();

  queryParamsSub$: Subscription;

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private appConfService: AppConfigService,
    public loaderService: MiniLoaderService,
    private helperService: HelperService
  ) {
    console.log('Version: 1.0.22');

    this.loader = false;
    this.showStartPage = true;

    this.getQueryParams();
  }

  ngOnDestroy(): void {
    this.queryParamsSub$?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.showStartPage = true;

    try {
      this.loaderService.loader = true;

      await this.appConfService.load();

      const tokenValidationResult = await this.authService.isTokenValid();

      if (!tokenValidationResult) {
        await this.authService.unautorized();
        return;
      }

      const userId = await this.authService.getUserId();

      if (!userId?.length) {
        await this.authService.unautorized();
        return;
      }

      const isAuth = await this.authService.isAuthenticatedCognitoGroups();

      if (!isAuth) {
        this.helperService.notify('User is not techadmin', 'ERROR');
        await this.authService.unautorized();
        return;
      }

      this.authService.validationResult = ValidationStatus.Valid;
      this.showStartPage = false;

      let urlLength = window.location.pathname.length;
      if (urlLength < 2) {
        this.router.navigate([environment.defaultPage]);
      }
    } catch (error) {
      await this.authService.unautorized();
      console.error(error);
    } finally {
      this.loaderService.loader = false;
    }
  }

  getQueryParams(): void {
    this.queryParamsSub$ = this.route.queryParams.subscribe((params) => {
      let email: string = params['email'] ?? '',
        code: string = params['code'] ?? '';

      if (email.indexOf(' ') > -1) {
        email = email.replace(/ /g, '+');
      }

      if (email?.length && code?.length) {
        this.showStartPage = false;
        this.router.navigate(['login'], {
          queryParams: {
            code: code,
            email: email,
          },
        });
      }
    });
  }

  checkAuthentication(): void {
    this.showStartPage = false;
    this.router.navigate(['login']);
  }
}
